<template>
    <div class="zt-page-content">
        <div class="flex flex-v" style="height: 100%;overflow-y: hidden;">
            <div class="flex-1" style="overflow-y:auto;">
                <div v-if="item && item.id" class="content">
                    <div class="zt-block">
                        <div class="content-top flex">
                            <div v-if="item.photo_render && item.photo_render.length>0" class="img-carousel">
                                <ItemImages :imgs="item.photo_render" :preUrl="imgUrl" width="400px"></ItemImages>
                            </div>
                            <div class="detail flex-1">
                                <h3 class="item-name ellipsis2">{{item.name}}</h3>
                                <div class="item-series flex">
                                    <div>{{item.style.name}}</div>
                                    <div>{{item.cat.name}}</div>
                                </div>
                                <div v-if="item.design_idea" class="design-idea">
                                    <div>设计理念:</div>
                                    <div class="font14-grey1">{{item.design_idea}}</div>
                                </div>
                                <div class="item-look flex">
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>累计点击</div>
                                    </div>
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>累计下载</div>
                                    </div>
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>第三方平台上架</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="zt-block">
                        <div style="padding:30px 20px;">
                            <div v-if="item.factory_list && item.factory_list.ing.length>0" class="facList">
                                <div v-if="item.factory_list.ing && item.factory_list.ing.length>0">
                                    <div v-for="(item1,idx1) in item.factory_list.ing" :key="idx1" class="facList-item">
                                        <div style="margin-bottom:20px" class="flex">
                                            <div class="facList-left flex">
                                                <div style="width:160px;height:160px;margin-right:30px;">
                                                    <div v-if="item1.logo" class="bg_img"
                                                    :style="{backgroundImage: 'url(' + imgUrl+item1.logo+'!width_250px' + ')'}"></div>
                                                    <div v-else class="bg_img"
                                                    :style="{backgroundImage: 'url(' + staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px' + ')'}"></div>
                                                </div>
                                                <div style="position:relative;max-width:400px" class="flex-1">
                                                    <h3 style="color:#4D4D4D;margin-top:5px;" class="item-name ellipsis1">{{item1.name}}</h3>
                                                    <div v-if="item1.aptitude && item1.aptitude.ability_service" class="fac-label flex">
                                                        <div v-for="(fwbq,idx) in item1.aptitude.ability_service.fwbq" :key="idx"
                                                            >{{fwbq.v}}</div>
                                                    </div>
                                                    <div v-if="item1.aptitude && item1.aptitude.real_check && item1.aptitude.real_check.expired_at !=0" style="position:absolute;bottom:0;left:0px">
                                                        <span style="margin-right:8px;" class="font14-grey1">
                                                            <i style="font-size:14px;color:#DFA64E;margin-right:8px;" class="iconfont icon-xuanzhongduihao1"></i>已实地认证</span>
                                                        <span style="font-size:12px;color:#CCCCCC;">{{'2019/09/04' | moment("YYYY/MM/DD")}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="facList-right flex-1">
                                                <div style="max-height:152px;background:#F5F5F5;padding:20px;" class="flex">
                                                    <div style="text-align:center;font-size:16px;line-height:112px;padding:0 20px;" class="fac-r-title ">工艺质量：</div>
                                                    <div v-if="item1.aptitude && item1.aptitude.relation && item1.aptitude.relation.ability_make" class="fac-r-list flex-1" style="min-width:192px">
                                                        <div v-for="(make,idx) in item1.aptitude.relation.ability_make.slice(0,3)" :key="idx" class="flex">
                                                            <div style="margin-right:30px">{{make.name}}</div>
                                                            <div v-for="(quota,idx) in make.quota" :key="idx"
                                                                style="margin-right:10px;">{{quota.name}}</div>
                                                        </div>
                                                        <div class="">
                                                            <el-button @click="viewZizhi(item1.id)" size="small" type="text">
                                                                <span>查看更多</span>
                                                                <i style="font-size:12px;" class="iconfont icon-fanhuitubiao1"></i>
                                                            </el-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="facList-item-bottom flex">
                                            <div class="flex-1">款式 <span>--</span></div>
                                            <div class="flex-1">现货总量<span>--</span></div>
                                            <div class="flex-1">月销量<span>--</span></div>
                                            <div class="flex-1">累计销量<span>--</span></div>
                                            <div style="min-width:300px">累计收益<span>￥{{item1.sale_amount | priceFilt}}</span></div>
                                        </div>
                                        <div class="more">
                                            <el-popover width="160" trigger="click">
                                                <div class="more-list">
                                                    <div @click.stop="stopCoop(item1.factory_product_no)">终止合作</div>
                                                    <!-- <div>加入黑名单</div>
                                                    <div>投诉工厂</div> -->
                                                </div>
                                                <el-button slot="reference" size="small" type="text">更多</el-button>
                                            </el-popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="facList-item">
                                <div class="title flex">
                                    <div class="line flex-1"></div>
                                    <div class="text">暂无合作工厂</div>
                                    <div class="line flex-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="zt-block">
                        <div style="width:1110px;margin:0 auto;padding:24px 0;">
                            <div class="tab_menu flex">
                                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                                    {{item.name}}
                                </div>
                            </div>
                            <div class="content-box flex">
                                <div class="pic-wrap-left">
                                    <div class="active">
                                        <i></i><span>标高图</span>
                                    </div>
                                    <div class="line"></div>
                                    <div>
                                        <i></i><span>素材图</span>
                                    </div>
                                </div>
                                <div v-if="tabIndex==0">
                                    <div class="content-img">
                                        <div class="ellipsis" style="font-size:22px;color:#808080;padding-top:20px;"
                                            >{{item.name}}</div>
                                        <div v-if="item.dimension" class="size">
                                            <label v-for="(size,idx) in item.dimension" :key="idx">{{size}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                        </div>
                                        <div style="font-size:18px;color:#808080;padding:20px 0 10px 0;">标高图</div>
                                        <div v-if="item.photo_size && item.photo_size.length>0">
                                            <div v-for="(item,idx) in item.photo_size" :key="idx">
                                                <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                            </div>
                                        </div>
                                        <div v-else>
                                            <img src="../../../assets/images/desgin/zwt.png" alt="">
                                        </div>
                                        <div v-if="item.photo_story && item.photo_story.length>0">
                                            <div style="font-size:18px;color:#808080;padding:30px 0 20px 0;">素材图</div>
                                            <div v-for="(item,index) in item.photo_story" :key="index">
                                                <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tabIndex>0">
                                    <div v-for="(item,idx) in item.accessory" :key="idx">
                                        <div v-if="tabIndex===(idx+1)" class="content-img">
                                            <div class="ellipsis" style="font-size:22px;color:#808080;padding-top:20px;"
                                                >{{item.name}}</div>
                                            <div v-if="item.size" style="font-size:14px;color:#808080;"
                                                >{{item.size.len}}mm*{{item.size.wlen}}mm*{{item.size.height}}mm</div>
                                            <div style="font-size:18px;color:#808080;padding:20px 0 10px 0;">标高图</div>
                                            <div v-if="item.photo_size && item.photo_size.length>0">
                                                <div v-for="(item,idx) in item.photo_size" :key="idx">
                                                    <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                                </div>
                                            </div>
                                            <div v-else>
                                                <img src="../../../assets/images/desgin/zwt.png" alt="">
                                            </div>
                                            <div style="font-size:18px;color:#808080;padding:30px 0 20px 0;">素材图</div>
                                            <div v-if="item.photo_story && item.photo_story.length>0">
                                                <div v-for="(item,index) in item.photo_story" :key="index">
                                                    <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                                </div>
                                            </div>
                                            <div v-else>
                                                <img src="../../../assets/images/desgin/zwt.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="item &&item.photo_cad" class="content-cad flex">
                                <div>设计CAD文件：</div>
                                <div class="flex-1 flex">
                                    <img src="../../../assets/images/desgin/icon_cad.png" alt="">
                                    <div v-if="item && item.photo_cad" style="padding-top:50px" class="font14-grey1">{{item.photo_cad.length}}份</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="fixed-bottom">
                <div v-if="item && item.price_percent" style="text-align:center;font-size:32px;color:#EE1818;">
                    合作比例：{{item.price_percent | percentFilt}}%
                </div>
            </div>

        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <ItemStopCoop v-if="popTitle=='终止合作'" :itemId="factoryId" @hide="popVisible=false"></ItemStopCoop>
        </el-dialog>

    </div>
</template>

<script>
    import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
    import { designPercentId } from '@/service/design';
    import ItemImages from '@/components/ItemImages.vue';
    import ItemStopCoop from './ItemStopCoop.vue';
    export default {
        components: {
            ItemImages,
            ItemStopCoop,
        },
        props:{
            
        },
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                staticUrl:STATIC_URL_PRE,
                popTitle: null,
                popVisible: false,
                tabList:[
                    {name:"主作品"},
                ],
                tabIndex:0,
                item:null,
                factoryId:null,
            }
        },
        watch: {
        },
        computed: {
            
        },
        created:function() {
            designPercentId(this.$route.params.id).then(rst=>{
                if (rst) {
                    this.item=rst;
                    if (this.item.accessory && this.item.accessory.length>0) {
                        this.item.accessory.forEach(e => {
                            this.tabList.push({name:e.name});
                        });
                    }
                }
                console.log(this.item);
            });
        },
        methods: {
            viewZizhi(id) {
                window.open('/zizhi/view/'+id);
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
                console.log(index);
            },
            stopCoop(id) {
                this.popTitle = "终止合作";
                this.popVisible = true;
                this.factoryId = id;
            },
            paySubmit() {
                this.popTitle = "签约单";
                this.popVisible = true;
            },
            popRz() {
                this.popTitle = "打样认证";
                this.popVisible = true;
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped src="../detail.css"></style>
<style scoped>
    .content {
        /* width: 80%; */
        min-width: 1360px;
        padding:0 30px;
        background: transparent;
        margin: 0 auto;
    }
    .content>div {
        width: auto;
        background:rgba(255,255,255,1);
    }
    .content-top {
        padding: 64px 84px 45px;
    }
    .content-top .detail {
        margin-left: 45px;
        position: relative;
    }
    .item-name {
        font-size: 24px;
        color: #292929;
        line-height: 28px;
        margin: 12px 0;
    }
    .zt-block {
        border-radius: 2px;
    }
    .item-series {
        font-size: 14px;
        color: #808080;
        line-height: 18px;
        margin-bottom: 54px;
    }
    .item-series>div {
        padding: 0 24px;
        border-right: 1px solid #CCCCCC;
    }
    .item-series>div:first-child {
        padding-left: 0;
    }
    .item-series>div:last-child {
        padding-right: 0;
        border-right: none;
    }
    div.design-idea {
        max-width: 650px;
        padding: 8px 16px 16px 16px;
        background: rgba(43,186,174,0.05);
    }
    .design-idea>div:first-child {
        font-size: 16px;
        color: #4D4D4D;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .item-look {
        position: absolute;
        left: 0;
        bottom: 87px;
    }
    .item-look .look-item {
        min-width: 128px;
        height: 70px;
        text-align: center;
        padding: 8px 0;
        border-radius: 4px;
        background:rgba(255,145,0,0.1);
        margin-right: 20px;
    }
    .look-item>div:first-child {
        font-size: 24px;
        color: #F66F6A;
        line-height: 33px;
    }
    .look-item>div:last-child {
        font-size: 16px;
        color: #4D4D4D;
        line-height: 22px;
    }
    /* -------------工厂信息-------------- */
    .facList-item {
        position: relative;
        padding: 30px 30px;
        border:1px solid rgba(204,204,204,1);
        box-shadow:0px 3px 6px rgba(0,0,0,0.16);
        border-radius:6px;
        margin-bottom: 16px;
    }
    .facList-item:last-child {
        margin-bottom: 0;
    }
    .facList-left {
        padding-right: 60px;
        border-right: 1px solid #CCCCCC;
    }
    .fac-label {
        max-width: 400px;
    }
    .fac-label>div {
        font-size: 14px;
        color: #016FFF;
        background:rgba(1,111,255,0.2);
        border-radius: 12px;
        padding: 0 16px;
        height: 24px;
        line-height: 24px;
        margin-right: 16px;
        margin-bottom: 6px;
    }
    .fac-label>div:nth-child(4n) {
        margin-right: 0;
    }
    .fac-label>div:nth-child(2),.fac-label>div:nth-child(7) {
        background:rgba(43,186,174,0.2);
        color: #2BBAAE;
    }
    .fac-label>div:nth-child(3),.fac-label>div:nth-child(8) {
        background:rgba(230,162,60,0.2);
        color: #E6A23C;
    }
    .fac-label>div:nth-child(4),.fac-label>div:nth-child(5) {
        background:rgba(246,111,106,0.2);
        color: #F66F6A;
    }
    .facList-right {
        padding-left: 35px;
        padding-right: 20px;
    }
    .facList-right>div {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
    }
    .fac-r-list>div {
        padding: 4px 0;
    }
    .icon-fanhuitubiao1 {
        display: inline-block;
        transform:rotate(90deg);
        -ms-transform:rotate(90deg); 	/* IE 9 */
        -moz-transform:rotate(90deg); 	/* Firefox */
        -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
        -o-transform:rotate(90deg); 	/* Opera */
    }
    .facList-item-bottom {
        background: rgba(219,162,76,0.1);
        padding: 10px 0;
        font-size: 20px;
        color: #4D4D4D;
    }
    .facList-item-bottom>div {
        text-align: center;
        height: 45px;
        line-height: 45px;
    }
    .facList-item-bottom span {
        font-size: 30px;
        color: #F66F6A;
        padding-left: 12px;
    }
    .more {
        position: absolute;
        top: 4px;
        right: 10px;
    }
    .more-list>div{
        padding: 0 12px;
        height: 32px;
        line-height: 32px;
        background: #fff;
        font-size: 14px;
        color: #707070;
        cursor: pointer;
    }
    .more-list>div:hover {
        background: #F5F5F5;
    }
    .title {
        width: 45%;
        margin: 0 auto;
        padding: 20px 0;
    }
    .title .line {
        position: relative;
        top: -25px;
        border-bottom: 1px solid #707070;
    }
    .title .text {
        padding: 0 16px;
        font-weight: 500;
        font-size: 36px;
        color: #CCCCCC;
    }
</style>
<style scoped>
    /* -------中间部分--------- */
    /*----选项卡----*/
    .tab_menu {
        padding: 0 120px;
        height: 90px;
        line-height: 86px;
        background:rgba(230,230,230,1);
        margin-bottom: 30px;
    }
    .tab_menu .menu_item{
        padding: 0 50px;
        height:90px;
        line-height: 86px;
        cursor: pointer;
        font-size: 20px;
        color: #4D4D4D;
        position: relative;
    }
    .tab_menu .menu_item.cur{
        background: #fff;
    }
    .tab_menu .menu_item.cur::before {
        content: '';
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-top: 4px solid #8362E4;
    }
    /* ------底部------ */
    .fixed-bottom {
        width: 100%;
        min-width: 1360px;
        height: 108px;
        background:rgba(230,230,230,1);
    }
    .fixed-bottom>div {
        padding: 30px 0;
        width: 1260px;
        margin: 0 auto;
    }

</style>

