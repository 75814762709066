<template>
    <div style="max-width:350px;margin:0 auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="80px" class="login-form">
            <el-form-item prop="type" label="终止原因">
                <el-radio-group v-model="ruleForm.type">
                    <el-radio style="display:block;padding-top:15px" v-for="(item,idx) in checkList" :key="idx" 
                        :label="item.name"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="desc" label="描述">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="ruleForm.desc"></el-input>
            </el-form-item>
            <div style="padding-top:10px;" class="flex flex-justify-around">
                <el-button @click="hide()" size="small" type="primary" plain>取消</el-button>
                <el-button @click="submitForm()" size="small" type="primary">确认</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { endPercentProduct } from '@/service/design';
    export default {
        props:['itemId'],
        data() {
            return {
                rules: {
                    type: [
                        { required: true, message: '请选择终止原因', trigger: 'blur' }
                    ],
                    // desc: [
                    //     { required: true, message: '请输入内容', trigger: 'blur' }
                    // ]
                },
                checkList:[
                    {id:1,name:"对方未按约定分成交易"},
                    {id:2,name:"对方未按约定生产"},
                    {id:3,name:"对方提不想继续合作"},
                    {id:4,name:"其他"},
                ],
                ruleForm:{
                    type:"",
                    desc:"",
                },
                list:[],
            }
        },
        watch: {

        },
        created() {
            console.log(this.itemId);
        },
        mounted() {},
        methods: {
            hide() {
               this.$emit("hide")
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                endPercentProduct({factory_product_no:this.itemId}).then(()=>{
                    this.hide();
                    this.$message({ type: 'success', message: '终止成功!' });
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    
</style>